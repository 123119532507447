import { helloWorld } from "./hello_world"

export const body = /*html*/`
<body>

<header>
    <img src="../images/green_lion1.png" class="image_header">
</header>


<div class="link_tree">
<a href="https://www.youtube.com/@iamthegreenlion" target=”_blank” >Full Playlist</a>
<a href="https://www.instagram.com/iamthegreenlion/" target=”_blank” >Instagram</a>
<a href="https://ko-fi.com/iamthegreenlion/shop" target=”_blank” >Shop</a>
<a href="mailto:hello@iamthegreenlion.com">Contact</a>
</div>

<footer>
<img src="../images/green_lion2.png" class="image_footer">
<p>All © by Dominik Sigrist, Kevin Bloch, 2024</p>
</footer>

</body>
`
